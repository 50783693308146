import React, { useRef } from 'react';
import { Form, Tooltip, Switch, Button, message } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import './ConfigForm.scss';
import { useGetClusterConfig, useSaveClusterConfig } from 'hooks/cluster';
import { ClusterConfigurations } from 'types/cluster.type';
import { FormattedMessage, useIntl } from 'react-intl';

import Timeout from './Timeout/Timeout';
import SparkSafeGuard, {
  SparkCallbackRef,
} from './SparkSafeGuard/SparkSafeGuard';
import { convertMinutesToHM } from 'utils/date';
import SpinnerChidori from 'components/SpinnerChidori/SpinnerChidori';

interface ModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (visible: boolean) => void;
}

const ConfigForm: React.FC<ModalProps> = props => {
  const safeGuardRef = useRef<SparkCallbackRef>(null);

  const [form] = Form.useForm();
  const intl = useIntl();

  const { mutateAsync: mutateSaveClusterConfig, isLoading } =
    useSaveClusterConfig();

  const { data: clusterConfig } = useGetClusterConfig();

  const sparkProperties = clusterConfig?.sparkSafeGuardProperties;

 const handleCancel = () => {
   props.setIsModalVisible(false);
 };

 const { hours, minutes } = convertMinutesToHM(clusterConfig?.timeout || 0);

 const initialValues = {
   hours: hours,
   minutes: minutes,
   killOnTimeout: clusterConfig?.killOnTimeout,
   useQueue: !clusterConfig?.skipQueue,
   useRealTimeLogs: clusterConfig?.realTimeLogs,
   enableTimeout: (clusterConfig?.timeout || 0) > 0,
   warningEmails: clusterConfig?.warningListEmails || [],
 };

 const handleOk = async () => {
   try {
     const values = await form.validateFields();

     const enableTimeout = values.enableTimeout;
     const config: ClusterConfigurations = {
       skipQueue: !values.useQueue,
       realTimeLogs: values.useRealTimeLogs,
       timeout: enableTimeout ? values.hours * 60 + values.minutes : 0,
       killOnTimeout: values.killOnTimeout,
       warningListEmails: values.warningEmails || [],
       sparkSafeGuardProperties: safeGuardRef.current?.getProperties() || {},
     };

     await mutateSaveClusterConfig({ config });
     props.setIsModalVisible(false);
     message.success(intl.formatMessage({ id: 'settings.success' }));
   } catch (error: any) {
     const errorMessage =
       error instanceof Error ? error.message : String(error);
     switch (errorMessage) {
       case intl.formatMessage({ id: 'settings.validationTableError' }):
         message.error(errorMessage);
         break;
       default:
         message.error(
           intl.formatMessage({ id: 'settings.validationFieldError' })
         );
     }
   }
 };

 if (!clusterConfig) {
   return <SpinnerChidori />;
 }

 return (
   <Form
     className="config-form"
     form={form}
     layout="horizontal"
     colon={false}
     initialValues={initialValues}>
     <section className="config-form__section-switch">
       <Form.Item
         name="useQueue"
         label={
           <span className="config-form__section-headers-titles">
             <FormattedMessage id="settings.useQueue" />
             <Tooltip title={intl.formatMessage({ id: 'settings.queueInfo' })}>
               <InfoCircleOutlined />
             </Tooltip>
           </span>
         }
         valuePropName="checked">
         <Switch />
       </Form.Item>
       <Form.Item
         name="useRealTimeLogs"
         label={
           <span className="config-form__section-headers-titles">
             <FormattedMessage id="settings.realTimeLogs" />
             <Tooltip title={intl.formatMessage({ id: 'settings.logsInfo' })}>
               <InfoCircleOutlined />
             </Tooltip>
           </span>
         }
         valuePropName="checked">
         <Switch />
       </Form.Item>
     </section>

     <Timeout form={form} />

     <SparkSafeGuard properties={sparkProperties || []} ref={safeGuardRef} />

     <section className="config-form__footer">
       <Button key="cancel" onClick={handleCancel}>
         <FormattedMessage id="cancel" />
       </Button>

       <Button
         className="config-form__footer-button"
         key="submit"
         type="primary"
         loading={isLoading}
         onClick={handleOk}>
         <FormattedMessage id="saveChanges" />
       </Button>
     </section>
   </Form>
 );
};

export default ConfigForm;